import React from 'react'
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Blog = ({ pageContext }) => {
    const { posts } = pageContext


    return (
        <Layout>
            <SEO title="Blog" />
            <h1>Cikkeink</h1>
            {
                posts.map(post => (
                    <article className="card post-card" id={`post-${post.id}`} key={post.id}
                        style={{
                            display: `flex`,
                            flexDirection: `column`,
                            justifyContent: `flex-start`,
                            alignItems: `flex-start`,
                            padding: `25px`,
                            boxShadow: `rgb(236, 236, 236) 3px 3px 10px 0`,
                            borderRadius: `6px`,
                        }}
                    >
                        <h2 style={{margin: 0,marginBottom: 10}}>{post.title}</h2>
                        <h4>{post.categories.map(category => <Link class="category_link" to={`/category/${category.slug}`}> | {category.name} </Link>)}|</h4>
                        <div dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
                        <Link to={`/${post.slug}`}
                            style={{
                                width: `200px`,
                                height: `40px`,
                                lineHeight: `40px`,
                                borderRadius: `6px`,
                                backgroundColor: `#e62d56`,
                                color: `#ffffff`,
                                textTransform: `uppercase`,
                                textDecoration: `none`,
                                textAlign: `center`,
                                marginTop: `0px`,
                            }}
                        >Tovább olvasom</Link>
                    </article>
                ))
            }

        </Layout>
    )
}

export default Blog
